@import url('https://fonts.googleapis.com/css2?family=Lora:ital@0;1&display=swap');


body {
  margin: 0;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


.carousel .carousel-img{
    background-color: #C6EBC5;
    margin: 0;
    min-width: 100%;
    max-height: 95vh;
    display: flex;
} 


.carousel-item {
    transition: ease-out 1s;

}
.container-navbar {
    background-color: rgba(224, 253, 245, 90%);   
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 5;
    margin-top: 100px;
}

.container-fluid {
    margin-top: 20px;
}

.container {
    margin: 0px;
}

.container-navbar a {
    color: #3C6255;
    border-radius: 5px;
    margin: 0 2px;
    font-weight: 600;
    font-size: 18px;
}

.navbar {
    margin: 0;
    padding: 0;
}

.mb-3 {
    margin-bottom: 0;
}

.navbar-nav {
    margin: 15px;
    padding: 0;
}

.navbar-expand {
    justify-content: space-between;
} 

.nav-link:hover {
    color: white;
    background-color: #3C6255;
    border-radius: 5px;
    box-shadow: 1px 1px 1px #A1C298 ;
}

h2 {
    font-weight: 100;
    margin: 0 8px;
}



/* ######  MENU LATERAL #######*/
.navbar-toggler-icon{
    width: 1em;
    height: 1em;
}

.offcanvas {
    font-family: 'Lora';
    background-color: #e0fdf5;   
}

.offcanvas-body .nav-link {
    padding-left: 5px;

}

.offcanvas-title {
    text-decoration: none;
    font-weight: 600;
    color: #3C6255;
}

.offcanvas-title:hover {
    color:#3C6255;
}



/* ###### MEDIA QUERIES ###### */

/* Large screen */
@media (min-width:991px) {
    .logo-nav {
        height: 90px;
        width: 200px;
        display: flex;
        margin: 10px 10px 0 20px;
    }
}

/* Medium Screen */
@media (min-width:581px) and (max-width:990px) {
    .logo-nav {
        height: 70px;
        width: 150px;
        display: flex;
        margin: 10px 10px 0 20px;
    }

    .space {
        display: none;
    }
}

/* Small Screen */
@media (max-width:580px) {
    .logo-nav {
        height: 45px;
        width: 100px;
        display: flex;
        margin: 10px 10px 0 20px;
    }

    .space {
        display: none;
    }
}



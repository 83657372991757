.main-container {
    background-color: #e0fdf5;
}

h4 {
    color: #ea80b0;
    font-weight: bold;
}

@media (min-width:581px) {
    h4 {
        margin: 30px 30px 0 30px;
    }

}

@media (max-width:580px){
    h4 {
        margin: 20px 20px 0 20px;
        font-size: 18px;
    }
   
}
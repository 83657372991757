.navsup-body {
    background-color: #1e676e;
    color: white;
    font-weight: 600;
    display: flex;
    margin: 0;
    /* min-height: 5vh; */
    align-items: center;
    z-index: 10;
    position: relative;
}

.bi {
    margin: 0 5px 0 10px;
    padding: 1px;
    font-size: 14px;
}

.navsup-body .bi-telephone-fill{
    margin-left: auto;
}    

h6 {
    margin-right: 15px;
}






@media (min-width:581px) {
    h6 {
        font-size: 16px;
    }

}

@media (max-width:580px){
    h6 {
        font-size: 14px;
    }

    .bi {
        font-size: 10px;
        margin: 0 5 0 5px;
    }
   
}